<template>
  <div class="full-text-inner">
    <div id="editorjs" />
  </div>
</template>

<script>
import { coreApi } from '@/api'
import { mapState } from 'vuex'
import EditorJS from '@editorjs/editorjs'
import Header from '@editorjs/header'
// eslint-disable-next-line import/no-extraneous-dependencies
import Image from '@editorjs/image'
import Embed from '@editorjs/embed'
// eslint-disable-next-line import/no-extraneous-dependencies
import Quote from '@editorjs/quote'
// eslint-disable-next-line import/no-extraneous-dependencies
import LinkTool from '@editorjs/link'
import List from '@editorjs/list'
// eslint-disable-next-line import/no-extraneous-dependencies
import Delimiter from '@editorjs/delimiter'
import Table from '@editorjs/table'
import Underline from '@editorjs/underline'
import Paragraph from '@editorjs/paragraph'
// eslint-disable-next-line import/no-extraneous-dependencies
import Marker from '@editorjs/marker'
import AlignmentTuneTool from 'editorjs-text-alignment-blocktune'
// eslint-disable-next-line import/no-extraneous-dependencies
import DragDrop from 'editorjs-drag-drop'
import AttachesTool from '@editorjs/attaches'
// import ColorPlugin from 'editorjs-text-color-plugin'
import VideoTool from '@shhred/editor-js-video-tool'

export default {
  name: 'FullText',
  props: {
    value: {
      type: [Array, String],
      default: '',
    },
  },
  data() {
    return {
      editor: null,
      defaultData: [
        {
          id: 1,
          type: 'paragraph',
          data: {
            text: 'Тут будет ваш текст',
          },
        },
      ],
    }
  },
  computed: {
    ...mapState('main', ['user']),
    textArticle: {
      get() {
        return this.value;
        // Редактируем блок кода
        // return this.value.map(i => {
        // if (i.type === 'code') {
        //   return {
        //     ...i,
        //     data: {
        //       caption: i.data.caption || '',
        //       code: i.data.code || '',
        //       language: i.data.language || 'php',
        //     },
        //   }
        // }

        //   return i
        // })
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      console.log('this.textArticle', this.textArticle);
      this.editor = new EditorJS({
        holderId: 'editorjs',
        tools: {
          header: {
            class: Header,
            inlineToolbar: true,
            tunes: ['alignment'],
          },
          paragraph: {
            class: Paragraph,
            inlineToolbar: true,
            tunes: ['alignment'],
          },
          image: {
            class: Image,
            config: {
              uploader: {
                uploadByFile: async file => await this.uploadFile(file),
              },
              types: 'image/*',
              // types: 'image/*, video/*',
            },
          },
          attaches: {
            class: AttachesTool,
            config: {
              uploader: {
                uploadByFile: async file => await this.uploadFile(file, 'attach'),
              },
              types: '*/*',
            },
          },
          Quote,
          LinkTool,
          List,
          Delimiter,
          Table,
          Underline,
          // Paragraph,
          Marker,
          embed: Embed,
          alignment: {
            class: AlignmentTuneTool,
          },
          code: editorjsCodeflask,
          Color: {
            class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
            config: {
              colorCollections: ['#EC7878','#9C27B0','#673AB7','#3F51B5','#0070FF','#03A9F4','#00BCD4','#4CAF50','#8BC34A','#CDDC39', '#FFF'],
              defaultColor: '#FF1300',
              type: 'text',
              customPicker: true,
            }
          },
          Marker: {
            class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
            config: {
              defaultColor: '#FFBF00',
              type: 'marker',
              icon: `<svg fill="#000000" height="200px" width="200px" version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" xml:space="preserve"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M17.6,6L6.9,16.7c-0.2,0.2-0.3,0.4-0.3,0.6L6,23.9c0,0.3,0.1,0.6,0.3,0.8C6.5,24.9,6.7,25,7,25c0,0,0.1,0,0.1,0l6.6-0.6 c0.2,0,0.5-0.1,0.6-0.3L25,13.4L17.6,6z"></path> <path d="M26.4,12l1.4-1.4c1.2-1.2,1.1-3.1-0.1-4.3l-3-3c-0.6-0.6-1.3-0.9-2.2-0.9c-0.8,0-1.6,0.3-2.2,0.9L19,4.6L26.4,12z"></path> </g> <g> <path d="M28,29H4c-0.6,0-1-0.4-1-1s0.4-1,1-1h24c0.6,0,1,0.4,1,1S28.6,29,28,29z"></path> </g> </g></svg>`
            }
          },
          video: {
            class: VideoTool,
            config: {
              uploader: {
                uploadByFile: async file => await this.uploadFile(file)
              },
            },
          },
        },
        data: {
          blocks: this.textArticle || this.defaultData,
        },
        /**
         * Internationalzation config
         */
        i18n: {
          /**
           * @type {I18nDictionary}
           */
          messages: {
            /**
             * Other below: translation of different UI components of the editor.js core
             */
            ui: {
              blockTunes: {
                toggler: {
                  'Click to tune': 'Нажмите, чтобы настроить',
                  'or drag to move': 'или перетащите',
                },
              },
              inlineToolbar: {
                converter: {
                  'Convert to': 'Конвертировать в',
                },
              },
              toolbar: {
                toolbox: {
                  Add: 'Добавить',
                },
              },
            },

            /**
             * Section for translation Tool Names: both block and inline tools
             */
            toolNames: {
              Text: 'Параграф',
              Heading: 'Заголовок',
              List: 'Список',
              Warning: 'Примечание',
              Checklist: 'Чеклист',
              Quote: 'Цитата',
              Code: 'Код',
              Delimiter: 'Разделитель',
              'Raw HTML': 'HTML-фрагмент',
              Table: 'Таблица',
              Link: 'Ссылка',
              Marker: 'Маркер',
              Bold: 'Полужирный',
              Italic: 'Курсив',
              InlineCode: 'Моноширинный',
              Underline: 'Подчеркнутый',
              Image: 'Фото',
            },

            /**
             * Section for passing translations to the external tools classes
             */
            tools: {
              /**
               * Each subsection is the i18n dictionary that will be passed to the corresponded plugin
               * The name of a plugin should be equal the name you specify in the 'tool' section for that plugin
               */
              warning: { // <-- 'Warning' tool will accept this dictionary section
                Title: 'Название',
                Message: 'Сообщение',
              },

              /**
               * Link is the internal Inline Tool
               */
              link: {
                'Add a link': 'Вставьте ссылку',
              },
              /**
               * The "stub" is an internal block tool, used to fit blocks that does not have the corresponded plugin
               */
              stub: {
                'The block can not be displayed correctly.': 'Блок не может быть отображен',
              },
            },

            /**
             * Section allows to translate Block Tunes
             */
            blockTunes: {
              /**
               * Each subsection is the i18n dictionary that will be passed to the corresponded Block Tune plugin
               * The name of a plugin should be equal the name you specify in the 'tunes' section for that plugin
               *
               * Also, there are few internal block tunes: "delete", "moveUp" and "moveDown"
               */
              delete: {
                Delete: 'Удалить',
              },
              moveUp: {
                'Move up': 'Переместить вверх',
              },
              moveDown: {
                'Move down': 'Переместить вниз',
              },
            },
          },
        },
        onChange: () => this.text(),
        onReady: () => {
          new DragDrop(this.editor)
        },
      })
    },
    text() {
      this.editor.save().then(({ blocks }) => {
        this.textArticle = blocks
      })
    },
    async uploadFile(file, type) {
      try {
        return await coreApi.uploadFile(file, this.user, type)
      } catch (e) {
        console.log('error', e)
      }
    },
  },
}
</script>

<style lang="scss">
.full-text-inner {
  width: 100%;
  border: 2px solid #f8f8f8;
  padding: 10px;
  border-radius: 5px;
  overflow: hidden;
  transition: all .4s;

  .editorjs-codeFlask_Wrapper {
    position: relative;
    z-index: 0;

    .editorjs-codeFlask_LangDisplay {
      z-index: 100;
      padding: 2px 5px 0 12px;
      font-weight: 500;
    }

    .codeflask__textarea {
      color: #ff000000;
      z-index: 111;
      background: #ffffff00;
      padding-left: 18px;
    }
  }
}

#color-btn-text {
  svg {
    width: 13px;
    height: 13px;
  }
}

.ce-block__content {
  max-width: 90%;
}
.ce-toolbar__content {
  max-width: 90%;
}

</style>
